<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap">
        <c-input v-model="search.id" label="ID" search-form />
        <c-input v-model="search.name" :label="$t('shared.name')" search-form />
        <auto-complete v-model="search.userId" item-text="fullName" :search="userSearch" :label="$t('users.user')" search-form />
        <auto-complete
          v-model="search.organizationId"
          :search="organizationSearch"
          :label="$t('organizations.organization')"
          search-form
        />

        <auto-complete
          v-model="search.workingSiteId"
          :search="workingSiteSearch"
          :label="$t('workingSites.workingSite')"
          search-form
        />

        <date-picker v-model="search.created.min" search-form :label="$t('shared.createdMin')" />
        <date-picker v-model="search.created.max" search-form :label="$t('shared.createdMax')" />
        <c-input v-model="search.seen" :items="$booleanOptions" :label="$t('alerts.seen')" select search-form />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div v-if="item.id > 0" class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="info" :to="`view/${item.id}`" v-on="on">
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.view') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.option`]="{item}">
        {{ $t(`alerts.${item.alertType}.${item.option}`) }}
      </template>

      <template #[`item.created`]="{item}">
        <DateDisplay :value="item.created" />
      </template>

      <template #[`item.user`]="{item}">
        <span v-if="item.userInfo">
          {{ item.userInfo.fullName }}
        </span>
      </template>

      <template #[`item.alertType`]="{item}">
        <v-chip v-if="item.alertType" small :color="typeColor(item)">
          {{ $t(`alerts.alertTypeInfo.${item.alertType}`) }}
        </v-chip>
      </template>

      <template #[`item.priority`]="{item}">
        <v-chip v-if="item.priority" small :color="priorityColor(item)">
          {{ item.priority }}
        </v-chip>
      </template>

      <template #[`item.organization`]="{item}">
        <span v-if="item.organizationInfo">
          {{ item.organizationInfo.name }}
        </span>
      </template>

      <template #[`item.workingSite`]="{item}">
        <span v-if="item.workingSiteInfo">
          {{ item.workingSiteInfo.name }}
        </span>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('users.user'), value: 'user'},
        {text: this.$t('workingSites.workingSite'), value: 'workingSite'},
        {text: this.$t('alerts.subject'), value: 'subject'},
        {text: this.$t('alerts.alertType'), value: 'alertType'},
        {text: this.$t('alerts.priority'), value: 'priority'},
        {text: this.$t('shared.created'), value: 'created'},
        {text: this.$t('shared.title'), value: 'option'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null,
        name: null,
        userId: null,
        organizationId: null,
        workingSiteId: null,
        created: {min: null, max: null},
        alertType: 'UV_DOSE'
      }
    }
  },
  created() {
    const unseen = this.$route.query.unseen
    if (unseen) {
      this.search.seen = false
    }
  },
  methods: {
    workingSiteSearch(filter) {
      return this.$api.workingSites.getAllInfo(filter)
    },
    userSearch(filter) {
      return this.$api.users.getAllInfo(filter)
    },
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    },
    typeColor(item) {
      const colorMapper = {
        UV_DOSE: 'primary',
        SUN_SCREEN: 'success'
      }
      return colorMapper[item.alertType]
    },
    priorityColor(item) {
      const colorMapper = {
        HIGH: 'error',
        MEDIUM: 'info',
        LOW: 'secondary'
      }
      return colorMapper[item.priority]
    },
    filter(isCount) {
      const filter = this.$filter()

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    getItems(filter) {
      return this.$api.alerts.getAll(filter, this.type).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.alerts.count(filter, this.type).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then((res) => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.alerts.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('alerts.alertRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
